(function(factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(['jquery'], factory);
	} else if (typeof module === 'object' && module.exports) {
		// Node/CommonJS
		module.exports = factory(require('jquery'));
	} else {
		// Browser globals
		factory(jQuery);
	}
})(function($) {
	$.fn.accordion = function() {

	    /*console.info('* accordion *', this);*/

		this.each(function() {
			var $this = $(this);
			var autoCollapse = $this.data('autoCollapse')
				? $this.data('autoCollapse')
				: false;
			var autoScroll = $this.data('autoScroll');
			if (typeof autoScroll !== typeof undefined && autoScroll !== false) {
				autoScroll = true;
			}

			var hash = window.location.hash.substr(1);
			var hashItem = null;
			var hashItemContent = null;

			if (autoCollapse && hash) {
				hashItem = $($this.find('#' + hash));
				hashItem.addClass('active');
				hashItemContent = $(hashItem.find('.js-Accordion__Content'));
			}

			if (!hashItem) {
				$this
					.children()
					.children('.js-Accordion__Content')
					.slideUp(0);
				// if (!$this.hasClass('MobileNav__List') && autoCollapse) {
				// 	$this
				// 		.find('.js-Accordion__Item' + (autoCollapse ? ':first' : ''))
				// 		.addClass('active');
				// 	console.log('accordion');
				// }
			} else {
				$this
					.find('.js-Accordion__Content')
					.not(hashItemContent)
					.slideUp(0);
				if (autoScroll) {
					document
						.querySelector('#' + hash)
						.scrollIntoView({ behavior: 'smooth' });
				}
			}
			// if (!hashItem) {
			// 	$this
			// 		.find('.js-Accordion__Content' + (autoCollapse ? ':not(:first)' : ''))
			// 		.slideUp(0);
			// 	if (!$this.hasClass('MobileNav__List') && autoCollapse) {
			// 		$this
			// 			.find('.js-Accordion__Item' + (autoCollapse ? ':first' : ''))
			// 			.addClass('active');
			// 		console.log('accordion');
			// 	}
			// } else {
			// 	$this
			// 		.find('.js-Accordion__Content')
			// 		.not(hashItemContent)
			// 		.slideUp(0);
			// 	document
			// 		.querySelector('#' + hash)
			// 		.scrollIntoView({ behavior: 'smooth' });
			// }

			$this
				.children()
				.children('.js-Accordion__Button')
				.click(function(j) {
					var dropDown = $(this)
						.closest('.js-Accordion__Item')
						.children('.js-Accordion__Content');

					var item = $(this).parent();

					if (!autoCollapse) {
						$this
							.children()
							.children('.js-Accordion__Content')
							// .find('.js-Accordion__Content')
							.not(dropDown)
							.slideUp();
						$this
							.children('.js-Accordion__Item')
							.not(item)
							.removeClass('active');
					}

					item.toggleClass('active');

                    // trigger scroll to trick lazyload
                    if(item.hasClass('active')) {
                        var y = $(window).scrollTop();  //your current y position on the page
                        $(window).scrollTop(y+1);
                       /* item.find('image').addClass('loaded');
                        var pic = item.find('picture');
                        console.log('pic:', pic);
                        pic.children().each(function() {
                            if ($(this).data('srcset')) {
                                console.log('data.src -> ', $(this).data('srcset'));
                                $(this).srcset = $(this).data('srcset');
                            }
                            if ($(this).hasClass('Image')){
                                console.log('data.src= ', $(this).data('src'));
                                $(this).src = $(this).data('src');
                            }*/
                        /*});*/



                    }

					// if (autoScroll) {
					// 	if (item.hasClass('active')) {
					// 		item.get(0).scrollIntoView({ behavior: 'smooth' });
					// 	}
					// }

					dropDown.stop(false, true).slideToggle();

					j.preventDefault();
				});
		});
	};
});
