(function(factory) {
	if (typeof define === 'function' && define.amd) {
		// AMD. Register as an anonymous module.
		define(['jquery'], factory);
	} else if (typeof module === 'object' && module.exports) {
		// Node/CommonJS
		module.exports = factory(require('jquery'));
	} else {
		// Browser globals
		factory(jQuery);
	}
})(function($) {
	$.fn.modal = function() {
		this.each(function() {
			var $this = $(this);
			var cta = $this.find('.js-Modal__Cta');
			var content = $this.find('.js-Modal__Content');
			var closeBtn = $('.Modal__CloseBtn');
			var container = $('.Modal');
			var bg = $(container.find('.Modal__Background'));
			// var dimmer = $(".Dimmer");
			var modalContent = $(container.find('.Modal__Content'));

			function closeModal() {
				container.removeClass('active');
				// container.addClass("out");
				// dimmer.removeClass("visible");
				// dimmer.addClass("out");
				$('body').removeClass('no-scroll');

				$('.Page').css({
					position: 'relative',
					top: 0
				});
				if (localStorage.cachedScrollPos) {
					$('body').scrollTop(localStorage.cachedScrollPos);
				}
			}

			bg.click(function() {
				closeModal();
			});

			function showModal() {
				modalContent.empty();
				if ($this.is('[no-transition]')) {
					var box = $('.Modal__Box');
					var transition = box.css('transition');
					box.css('transition', 'unset');
				}

				$('.Modal__Box').addClass('Modal__Box--' + $this.data('size'));

				// dimmer.addClass("visible");
				// dimmer.removeClass("out");
				var c = content.detach();

				// modalContent.html(content.html());
				modalContent.prepend(c);
				c.show();

				closeBtn.click(function() {
					closeModal();
				});

				// container.addClass("visible");
				container.addClass('active');
				// container.removeClass("out");
				$('body').addClass('no-scroll');
				$(window).trigger('resize');
				// if ($this.is("[no-transition]")) {
				// 	box.css("transition", transition);
				// }
			}

			if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
				$currentScrollPos = 0;
				$(window).scroll(function() {
					$currentScrollPos = $(document).scrollTop();
				});

				cta.click(function() {
					showModal();
					$('.Page').css({
						position: 'fixed',
						top: -$currentScrollPos
					});
					localStorage.cachedScrollPos = $currentScrollPos;
				});
			} else {
				cta.click(function(e) {
					e.preventDefault();
					showModal();
				});
			}
		});
	};
});
