'use strict';

var conf = require('./conf');
var $ = require('jquery');
window.jQuery = $;

window.screenXs = 480;
window.screenSm = 768;
window.screenMd = 992;
window.screenLg = 1300;

require('./libs/smoothscroll.js').polyfill();

// require('./libs/perfect-scrollbar.js');
require('./libs/owl.carousel.js');
require('./libs/lightgallery.js');
require('./libs/lg-zoom.js');
require('./libs/lg-autoplay.js');
require('./libs/lg-fullscreen.js');
// require('./jQueryPlugins/mainNav.js');
require('./jQueryPlugins/accordion.js');
require('./jQueryPlugins/modal.js');
require('./react/build/static/js/main.js');


//-----------------------------------------

var isMobile = {
	Android: function() {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function() {
		return (
			isMobile.Android() ||
			isMobile.BlackBerry() ||
			isMobile.iOS() ||
			isMobile.Opera() ||
			isMobile.Windows()
		);
	}
};

function init() {
    /*
	console.log('Initializing with', conf);
	console.log('Having jQuery version', $().jquery);
    */
	var lazyLoaderUpdate = function(event) {
		if (window.lazyLoader) window.lazyLoader.update();
	};

	$('.CookieNotice').each(function(index, element) {
		function getCookie(name) {
			var value = '; ' + document.cookie;
			var parts = value.split('; ' + name + '=');
			if (parts.length == 2)
				return parts
					.pop()
					.split(';')
					.shift();
		}
		if (!getCookie('cookieNotice')) {
			$(element).removeClass('disappear');
		}
		$(element)
			.find('.CookieNotice__Button')
			.click(function() {
				var cname = 'cookieNotice';
				var cvalue = 1;
				document.cookie = cname + '=' + cvalue + ';path=/';
				$(element).addClass('disappear');
			});
	});

	$('.Content').lightGallery({
        selector: '.JSLightGallery__GalleryItem',
        subHtmlSelectorRelative: true,
        fullScreen: false
	});

    // OWL CAROUSEL
	$('.GalleryElement__Gallery').each(function(index, element) {
		if ($(element).find('.GalleryElement__GalleryItem').length >= 7) {
			$(element).addClass('GalleryElement__Gallery--counter');
		}
		$(element).owlCarousel({
			loop: false,
			responsiveClass: true,
			navText: [],
			margin: 20,
			nav: true,
			dots: true,
			items: 1,
			responsive: {
				768: {
					margin: 50
				}
			},

			onRefreshed: lazyLoaderUpdate,
			onDragged: lazyLoaderUpdate,
			onTranslated: lazyLoaderUpdate
		});
	});

	$('.FactsAndFiguresElement__Gallery').each(function(index, element) {
		// if ($(element).find('.GalleryElement__GalleryItem').length >= 7) {
		// 	$(element).addClass('GalleryElement__Gallery--counter');
		// }
		var owl = $(element);
		function init() {
			owl.addClass('owl-carousel');
			owl.owlCarousel({
				loop: false,
				navText: [],
				margin: 0,
				nav: true,
				dots: true,
				items: 1,

				onRefreshed: lazyLoaderUpdate,
				onDragged: lazyLoaderUpdate,
				onTranslated: lazyLoaderUpdate
			});
		}

		if ($(window).width() < window.screenSm) {
			init();
		}
		$(window).resize(function() {
			if ($(window).width() < window.screenSm) {
				init();
			} else {
				owl.trigger('destroy.owl.carousel');
				owl.removeClass('owl-carousel');
			}
		});
	});

	$('.TeaserRowElement__Gallery').each(function(index, element) {
		var owl = $(element);
		var itemCount = owl.find('.TeaserRowElement__GalleryItem').length;
		var items = 4;
		items =
			typeof owl.attr('data-items') !== typeof undefined
				? parseInt(owl.attr('data-items'))
				: items;

		if (itemCount < 4) {
			items = itemCount;
		}
		var stagePadding = 0;

		var elements = owl.find('.TeaserRowElement__GalleryItem');

		function setMinHeight() {
			elements.height('auto');
			var minHeight = 0;
			elements.each(function(index, element) {
				var height = $(element).height();
				minHeight = Math.max(minHeight, height);
			});
			elements.height(minHeight);
		}

		if (
			typeof owl.attr('data-stagepadding') !== typeof undefined &&
			owl.attr('data-stagepadding') !== false
		) {
			stagePadding = 20;
		}
		owl.on('initialized.owl.carousel', function(event) {
			showHideCounter(event.item.count / event.page.size);
			// setMinHeight();
		});
		owl.owlCarousel({
			loop: false,
			responsiveClass: true,
			navText: [],
			margin: 20,
			nav: true,
			dots: true,
			items: 1,
			stagePadding: stagePadding,
			responsive: {
				768: {
					items: items === 1 ? 1 : 2,
					stagePadding: stagePadding * 2
				},
				992: {
					margin: 25,
					items: items === 1 ? 1 : items < 3 ? 2 : items - 1,
					stagePadding: 0
				},
				1300: {
					margin: items < 4 ? (4 - items) * 60 : 32,
					items: items,
					stagePadding: 0
				}
			},

			onRefreshed: lazyLoaderUpdate,
			onDragged: lazyLoaderUpdate,
			onTranslated: lazyLoaderUpdate
		});
		function showHideCounter(count) {
			if (count >= 7) {
				$(element).addClass('TeaserRowElement__Gallery--counter');
			} else {
				$(element).removeClass('TeaserRowElement__Gallery--counter');
			}
		}
		owl.on('resized.owl.carousel', function(event) {
			showHideCounter(event.page.count);
			// setMinHeight();
		});
	});

	$('.Stage__Gallery').each(function(index, element) {
		var owl = $(element);
		var elements = owl.find('.Stage__Item');
		var elementCount = owl.children().length;

		owl.on('initialized.owl.carousel', function(event) {
			if (!isMobile.any()) {
				owl.find('.active iframe').each(function(i, e) {
					var iframe = $(e);
					$(e).load(function() {
						e.contentWindow.postMessage(
							JSON.stringify({
								event: 'command',
								func: 'playVideo',
								args: []
							}),
							'*'
						);
					});
					iframe.attr('src', iframe.data('src'));
				});
				owl.find('.active .Stage__ItemCover').removeClass('visible');
			}
		});
		owl.owlCarousel({
			mouseDrag: elementCount > 1,
			touchDrag: elementCount > 1,
			loop: true,
			navText: [],
			margin: 0,
			nav: true,
			dots: false,
			items: 1,
			navSpeed: 1000,
			autoplay: true,
			onRefreshed: lazyLoaderUpdate,
			onDragged: lazyLoaderUpdate,
			onTranslated: lazyLoaderUpdate
		});

		owl.on('translated.owl.carousel', function(event) {
			owl.find('.active iframe').each(function(i, e) {
				e.contentWindow.postMessage(
					JSON.stringify({
						event: 'command',
						func: 'playVideo',
						args: []
					}),
					'*'
				);
			});
		});
		owl.on('change.owl.carousel', function(event) {
			if (event.property.name === 'position') {
				owl.find('iframe').each(function(i, e) {
					owl.find('iframe').each(function(i, e) {
						e.contentWindow.postMessage(
							JSON.stringify({
								event: 'command',
								func: 'stopVideo',
								args: []
							}),
							'*'
						);
					});
				});
			}
		});
	});

	// MODALS
	$('.js-Modal').modal();

	$('.Modal__CloseBtn').click(function() {
		$('.Modal').removeClass('active');
	});
	$('.Modal__Background').click(function() {
		$('.Modal').removeClass('active');
	});
	var container = $('.Modal');

	// INIT ACCORDIONS
	$('.js-Accordion').accordion();



	// INIT TABLE
	// $('.Table__Table').perfectScrollbar();

	$(function() {

		$('.js-Form').each(function (i, form) {

			var successText = $(form).find('.js-Form__Success');

			$(form).find('form').on({
				submit: function() {
					var $self = $(this);
					$('.alert.alert-success').remove();
					var submitBtn = $('button[type=submit]', $self);
					submitBtn.prop('disabled', true);
					submitBtn.addClass('disabled');

					$.ajax({
						type: 'post',
						dataType: 'json',
						data: $(this).serialize(),
						success: function(response) {
							$self.find('.FormField').removeClass('error');

							if (response.success && response.finished) {
								successText.css({ display: 'block' });
								$self.css({ display: 'none' });
							} else if (response.errors) {
								for (var key in response.errors) {
									if (!response.errors.hasOwnProperty(key)) continue;
									var messages = response.errors[key];
									$self.find('.FormField-' + key).addClass('error');

									var statusField = $self.find(
										'.FormField-' + key + ' > .FormField__Status'
									);
									statusField.html(messages.join(', '));
								}
							}

							submitBtn.prop('disabled', false);
							submitBtn.removeClass('disabled');
						}
					});

					return false;
				}
			});
		})

	});
}

var App = {
	init: init
};

$(function() {
	$(document).ready(function() {
		App.init();
	});
});
